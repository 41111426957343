@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Orbitron;
  src: url('./fonts/Orbitron-VariableFont_wght.woff');
  src: url('./fonts/Orbitron-VariableFont_wght.woff2');
}

@font-face {
  font-family: Rajdhani;
  font-weight: 700;
  src: url('./fonts/Rajdhani-Bold.woff');
  src: url('./fonts/Rajdhani-Bold.woff2');
}

@font-face {
  font-family: Rajdhani;
  font-weight: 300;
  src: url('./fonts/Rajdhani-Light.woff');
  src: url('./fonts/Rajdhani-Light.woff2');
}

@font-face {
  font-family: Rajdhani;
  font-weight: 500;
  src: url('./fonts/Rajdhani-Medium.woff');
  src: url('./fonts/Rajdhani-Medium.woff2');
}

@font-face {
  font-family: Rajdhani;
  font-weight: 400;
  src: url('./fonts/Rajdhani-Regular.woff');
  src: url('./fonts/Rajdhani-Regular.woff2');
}

@font-face {
  font-family: Rajdhani;
  font-weight: 600;
  src: url('./fonts/Rajdhani-SemiBold.woff');
  src: url('./fonts/Rajdhani-SemiBold.woff2');
}

@font-face {
  font-family: Aldrich;
  font-weight: 400;
  src: url('./fonts/Aldrich-Regular.woff');
  src: url('./fonts/Aldrich-Regular.woff2');
}




:root {
	--gradient-shadow: linear-gradient(
		45deg,
		#fb0000,
		#0000ff,
		#00bfff,
		#ff2f00,
		#3c3ce5,
		#cd0808,
		#ff2f00be,
		#0000ff,
		#ff1900,
		#ff0000
	);
  --gradient-shadow1: linear-gradient(
		45deg,
		#0060fb,
		#0000ff,
		#0066ff,
		#000dff,
		#00aaff,
		#0079fb,
		#0000ff,
		#108bd8,
		#0044ff,
		
	);
 
  
}

.btn3{
  background: linear-gradient(45deg ,   #0c8fe0,#ffffff, #ffffff , #ff0000);
  padding: 3px;
  border-radius: 3%;
  height: 80%;
}

.btn3:hover{
  padding: 5px;
  
}


.insta{  
  height: 50px;
  width: 50px;
  margin-left: 10vw;
}
.bgw{
  background-color: white;
}


.shadow1 {
  display: flex;
	justify-content: center;
	position: relative;
	color: #fff;
	background: linear-gradient(0deg, #f1f1f1, #ffffff);
}
.shadow1:before,
.shadow1:after {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	background: var(--gradient-shadow1);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: animate 2s linear infinite;
  border-radius: 2%;
}

.shadow1:after {
	filter: blur(20px);
}


.shadow {
  
	
	
	position: relative;
	color: #fff;
	background: linear-gradient(0deg, #f1f1f1, #ffffff);
}
.shadow:after {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	background: var(--gradient-shadow);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: animate 20s linear infinite;
  border-radius: 3%;
}

.shadow:after {
	filter: blur(20px);
}

@keyframes animate {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
}


.btn {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #4357d9 100%,
    #0ba4d3 117.04%
  );
  box-shadow: 2px 4.42184px 107.23px rgba(86, 142, 255, 0.51);
}



.btn:hover {
  background: linear-gradient(
    92.23deg,
    #e63e3ebb 21.43%,
    #f619197e 50.63%,
    #f80025 100%,
    #d30b0b 117.04%
  
  );
}

footer {
  background: #4880af; 
  padding: 32px 0;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
}
footer ul {
  
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobI{
  margin-bottom: -16vh;
  margin-left: 10vw;
  filter: contrast(1.5);
}

.svg{
  padding-bottom: 30px;
}

.contrast {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  filter: contrast(1.5);
  filter: brightness(1.3);
}

footer li a {
  padding: 1px;
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
}
footer li a:hover {
  color: white;
}

footer li div {
  padding: 1px;
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
}
footer li div:hover {
  color: white;
}

.Logo {
  filter: contrast(1.5);
}

.limg {
  width: 165px;
  height: 70px;
}

.borders {
  border: 2px solid;
  width: 240px;
  height: 60px;
  transition: width 1s, height 1s;
}



.borders:hover {
  width: 270px;
  height: 70px;
  
}

.bg2{
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.borders1 {
  border: 2px solid rgba(0, 0, 0, 0.373);
  

}

.scaling{
  scale: 1.4;
}

::-webkit-scrollbar {
  height: 8px;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb {
  background: #6e93f7;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4070f4;
}
.container1 {
  display: flex;
  gap: 12px;
  max-width: 400px;
  width: 100%;
  
  border-radius: 12px;
  padding: 30px;
  scroll-snap-type: x mandatory;
  
  scroll-padding: 30px;
  
}
.container1 .card {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 12px;
  background: white;
  scroll-snap-align: start;
  box-shadow: 0 15px 25px rgba(182, 18, 18, 0.1);
}



.card .image {
  height: 150px;
  width: 150px;
  padding: 4px;
  background: #4070f4;
  border-radius: 50%;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid #fff;
}



#scroll-container {
  border-radius: 5px;
  overflow: hidden;

}

#scroll-text {
  text-align: center;
  padding: 20px;
  padding-top: 25px;
  padding-bottom: 35px;


}

.sizex{
  height: 150%;
}



.headline{
  color: #303030;
  transition: width 1s, height 1s;
  font-weight: bold;
}

.headline:hover{
  font-weight: normal;
  color: #000000;
}

.nimg {
  width: 100%;
  height:100%;
  transition: width 1s, height 1s;
}

.nimg:hover{
  width: 105%;
  height: 105%;
}

.card h2 {
  margin-top: 25px;
  color: #333;
  font-size: 22px;
  font-weight: 600;
}
.card p {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  text-align: center;
}

.rborder{
  border: 1px solid red;
}

.showcase:hover{
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.background{
  background-color: #d30b0b;
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.pf{
  color: rgb(255, 255, 255);
  
  
}

.deGray{
  filter: contrast(1.5);
}

.bg1 {
  background-image: url('./assets/roof.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: grayscale(0.2)
}

.pfs{
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.pfs1{
  color: rgb(0, 0, 0);
  
}

body{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.margin-left{
  margin-left: 455px;
}

.bh1{
  color: #d30b0b;
}

.sizez{
  height: 120px;
  width: 340px;
}

.sizez3{
  height: 100px;
  width: 400px;
}

.sizez2{
  width: 285px;
  height: 55px;
}

.sizey{
  height: 400px;
  width: 400px;
}
  
.amarg-{
  margin-left: 100px;
}

.bh2{
  color: #4880af;
} 

h1{
  color: rgb(255, 255, 255);
}
.margin-none{
  margin-top: -49px;
  margin-bottom: 70px;
}

.snav{
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 5px 5px rgb(206, 202, 202);
}

.blue {
  background: #3c31dd;
}

.red {
  background: linear-gradient(
    180deg,
    #db1212 21.43%,
    #e15008 50.63%,
    #4880af 100%,
    #4880af 117.04%
  );
}



.pink {
  background: #ff0066;
  overflow: hidden;
}

.dark {
  background: #0f0f10;
}

/* Curved bg with plain CSS */



.spacer {
  aspect-ratio: 900/200;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}

.layer2 {
background-image: url('./assets/layer2.svg');
}  

.flip {
  transform: rotate(180deg);
}

/* Blobs SVG background image via https://haikei.app/ */




.sbackground {
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #ffffffce;
  z-index: 41;
}

.z1{
  z-index: 42;
}

sbbutton {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 27px;
  left: 27px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  
  
}

.sbackground2 {
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #dbd7d7;
  margin-top: 550px;
  margin-left: 470px;
  
}

.sbackground3 {
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #ffffff;
  margin-top: 2022.5px;
  margin-left: 470px;
  
}

.sbackground4 {
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #ffffff;
  margin-top: 2022.5px;
  margin-left: 470px;
  
}


sbbutton2 {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 27px;
  left: 27px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  margin-top: 550px;
  margin-left: 470px;
  
}

sbbutton3 {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 27px;
  left: 27px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  margin-top: 1780.5px;
  margin-left: 500px;
  
}

sbbutton4 {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 27px;
  left: 27px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  margin-top: 1822.5px;
  margin-left: 1400px;
  
}

.blobs {
  width: 100%;
  background: url('./assets/blobs.svg') no-repeat bottom / cover;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} 

uls,
lis {
  margin: 0;
  padding: 0;
}

uls {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
 
}

lis {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

uls2 {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
  margin-top: 550px;
  margin-left: 470px;
}

uls3 {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
  margin-top: 1822.5px;
  margin-left: 20px;
}

uls4 {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
  margin-top: 1822.5px;
  margin-left: 20px;
}


.margin3{
  margin-left: 310px;
  margin-top: 115px;
}

.margin4{
  margin-left: 780px;
  margin-top: -30px
}

.margin5{
  margin-left: 1160px;
  margin-top: -32.5px
}

.example-container div {
  background-image: url('./assets/Tondachziegel.png');
  border-radius: 30px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 150px / 2);
  left: calc(50% - 150px / 2);
}

.example-container .drag-area {
  opacity: 0.2;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 30px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  background-color: #3bace2;
}

.example-container2 div {
  background-image: url('./assets/Schiefer.png');
  border-radius: 30px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 150px / 2);
  left: calc(50% - 150px / 2);
}

.example-container2 .drag-area2 {
  opacity: 0.4;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 30px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  background-color: #3bace2;
}

.example-container3 div {
  background-image: url('./assets/Betond.png.webp');
  border-radius: 30px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 150px / 2);
  left: calc(50% - 150px / 2);
}

.example-container3 .drag-area3 {
  opacity: 0.2;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 30px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  background-color: #3bace2;
}

.example-container4 div {
  background-image: url('./assets/Faserzement.webp');
  border-radius: 30px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 150px / 2);
  left: calc(50% - 150px / 2);
}

.example-container4 .drag-area4 {
  opacity: 0.2;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 30px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  background-color: #3bace2;
}

.margin6{
  position: relative;
  margin-top: 200px;
  margin-right: 1000px
}

.margin7{
  position: relative;
  margin-top: 200px;
  margin-right: 440px
}

.margin8{
  position: relative;
  margin-top: 200px;
  margin-right: -280px
}

.margin9{
  position: relative;
  margin-top: 200px;
  margin-right: -1090px
}

.margin10{
  margin-left: 500px
}

.marginidk{
  margin-right: 200px
}

.title {
  font-size: 15em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: rgb(232, 12, 12);
}

#description {
  margin: 1.5em 0 2em 0;
}





.Ref1{
  width: 1850px;
  height: 900px;
  background-image: url(./assets/Neubaue.jpg);
  margin: auto;
  filter: brightness(60%);
  align-items: center;
  justify-items: center;
 text-align: center;
 flex: auto;

}

@media (max-width: 640px) 
{
   .Ref1
   {
      display: none;
   }
}

.Ref2{
  width: 1850px;
  height: 900px;
  background-image: url(./assets/Ref2.jpeg);
  margin: auto;
  filter: brightness(60%);
  align-items: center;
  justify-items: center;
 text-align: center;
 flex: auto;
 background-repeat: no-repeat;
}

.Ref3{
  width: 1850px;
  height: 900px;
  background-image: url(./assets/Ref3_cover.jpeg);
  margin: auto;
  filter: brightness(60%);
  align-items: center;
  justify-items: center;
 text-align: center;
 flex: auto;
 background-repeat: no-repeat;
}

.Ref4{
  width: 1850px;
  height: 900px;
  background-image: url(./assets/Ref4_cover.jpg);
  margin: auto;
  filter: brightness(60%);
  align-items: center;
  justify-items: center;
 text-align: center;
 flex: auto;
 background-repeat: no-repeat;
}

.bgw2{
  background-color: white;
}


@media only screen and (min-width: 1700px) {
.margin-auto2{
  margin-left: 300px;
}


}


.fullbright{
  filter: brightness(150%);
}


.sub-heading {
	padding-top: 50px;
	font-size: 18px;
} .sub-heading-two {
	font-size: 15px;
} .sub-heading-three {
	font-size: 13px;
} .sub-heading-four {
	font-size: 11px;
} .sub-heading-five {
	font-size: 9px;
} .sub-heading-six {
	font-size: 7px;
} .sub-heading-seven {
	font-size: 5px;
} .sub-heading-eight {
	font-size: 3px;
} .sub-heading-nine {
	font-size: 1px;
}

.refpic{
  height: auto;
  object-fit: contain;
  max-width: 400px;
}




.margin68{
  margin-left: 350px;
}

.anothermargin{
  margin-left: 350px;
}

@keyframes rotar {
	from {
		transform: rotateY(0deg);
	} to {
		transform: rotateY(360deg);
	}
}

.idk{
  box-shadow: 0px 10px 15px 3px rgba(221, 46, 46, 0.411);
}

.glasscard{
  padding: 20px;
  box-shadow: 0px 10px 15px 0.3px rgba(221, 46, 46, 0.411);
  border-radius: 5%;
  
}

.glasscard:hover{
  box-shadow: 0px 10px 15px 0.3px rgba(116, 163, 239, 0.417);
}


@media only screen and (min-width: 1700px) {
.fixglass{
  left : 20%
}

.paddt{
  padding-left: 150px;
  padding-right: 150px;
  margin-bottom: 50px;
}

.mob{
  display: none;
}

}

.mt10{
  margin-top: -30vh;
}
.card .glass h2 {
  padding: 20px;
  font-weight: 300;

}

.margin-right{
  margin-right: 600px;
}

.margin-left2{
  margin-left: 600px;
}

.dachimg{
  margin-left: 10vw;
  margin-top: 10vh;
}

.dachimg2{
  margin-left: 60vw;;
}






@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
  
  

}
/*ipad*/
@media only screen and (max-width: 1200px) {
  .mob{
    display: none;
  }

  .dachimg{
    margin-left: 5vw;
    margin-top: 10vh;
  }

  
.fixit{
  margin-left: 0vw;
}

  .dachimg2{
    margin-left: 55vw;;
  }


  .limg {
    margin-right: -4vw;
    margin-left: 4vw;
  }

  .con1{
    margin-right: 4vw;
  }

.con1 button{
  scale: 0.9;
  margin-right: 10px;
}

}

.mrd{
  margin-right: calc(10vw - 2vh);
}

.mld {
  margin-left: calc(6vw - 4vh);
}

.mg {
  width: 200vw;
}

/*mobile stuffs*/
@media only screen and (max-width: 700px) {
  .mm{
    margin-top: 200px;
  }
  .scaleM{
    scale: 65%;
  }
  
  .HeadlineM{
    transform: scale(60%);
    margin-left: -50px;
  }
  .mobiletext{
    font-size: 14px;
    line-height: 20px;
  }
  .infoM{
    margin-left: 100px;
    margin-top: -50px;
  }
  .infoM2{
    position: fixed;
    top: 300px;
    right: 150px;
    margin-top: -50px;
  }
  .cover{
    filter: opacity(0);
  }
  .hideM{
    filter: opacity(0);
    margin-top: -500px;
  }
  .pictureM{
    margin-left: 150px;
    transform: scale(80%);
  }
  .leftmarg{
    margin: auto;
  }
  .STM{
    margin: auto;
  }
  .Arbeiter{
    margin-left: -100px;
    transform: scale(60%);
  }
  .flex-mob{
    flex-direction: column;
  }

  .spc {
    margin-bottom: -40vh;
  }

  .dachimg{
    margin-top: -5vh;
  }



  .dachimg2 {
    margin-top: 4vh;
    margin-right:155vw;
    scale: 0.7;
    margin-bottom: 22vh;
  }

  .mup {
    top: -6.5vh;
    padding-right: 50vw;
  }

  .mup2{
    padding-left: 20vw;
  }

  .mb1{
    margin-top: -20vh;
    margin-bottom: -30vh;
  }

  .mb2{
    margin-left: 10vw;
  }
  
  .mt10 {
    margin-top: 0;
  }

  .FIX {
    display: none;
  }

  .desk{
    display : none
  }

  .mob {
    display: block;
    margin-top: 2vh;
  }

  footer{
    flex-direction: column;
  }
}

.resize2{
  width: 400px;
  height: 400px;
  
  display: block;
  margin-left: auto;
  margin-right: auto;
 
}
.add{
  width: 128px;
  height: 40px;
  margin: auto;
  margin-bottom: -3vh;
  margin-top: 1vh;
}

.Headline{
  color: rgb(57, 57, 57);
  font-weight: bolder;
  text-align: center;
  
}

.topelement{
  z-index: 9999;
}

 .padd{
    padding: 40px;
  }

.smallglass{
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  border: 1px solid #fff;
  
  overflow: hidden;
}

.coverp{
  width: 7px;
  height: 7px;
  background: #dbe6f8;
  position: absolute;
  margin-top: -17.5px;
}

.smallglass::before{
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  transform: skewX(180deg);
}

.smallglass h2{
  padding: 20px;
  font-weight: 300;
}

.bgsmall {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placehold {
  background-size: auto;
  background-color: #0000000f;
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  flex: auto;
  margin-top: 5vh;
}


.secondstat{
  margin-right: 1000px
}

.scale10{
  scale: 0.6;
  margin-left: -30px;
  margin-bottom: -20px
}

.maps{
  margin-top: -50px;
  margin-bottom: 200px
}

.contact{
  margin-bottom: 200px
}

.ticker-image {
  width: 320px;
  height: 230px;
  padding: 10px;
  border-radius: 15%;
  background-color: #f1f1f172;
  margin-right: 20px;
  border-style: solid;
  border-color: #e3e3e365;
  border-width: 1px;
}

.ticker2{
  rotate: 180deg;
}



.mrg{
  margin-right: 50px
}

.FIX{
  margin-top: -15vh;
  scale: 0.9;
  margin-bottom: -15vh
}



@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white ;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-secondary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#d30b0b] font-bold  hover:from-[#d30b0b] hover:to-[#42A6E3];
  }
  .section {
    @apply  py-8 lg:py-24 lg:h-screen flex items-center ;
  }
}
